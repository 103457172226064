@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Pretendard', 'Pretendard JP', 'Noto Sans SC', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scrollbar-width: none;
  -ms-overflow-style: none;
  user-select: none;
  max-height: 1920px;
  overflow: hidden;
}
body::-webkit-scrollbar {
  display: none;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
th {
  font-weight: 600;
  color: #030303;
}
td {
  font-weight: 400;
}
th,
td {
  font-size: 24px;
  width: 25%;
  height: 76px;
  text-align: center;
  color: #030303;
}
.hg-rows {
  display: flex;
  flex-direction: column;
  gap: 18px;
  padding: 12px;
}
.hg-button.hg-standardBtn {
  font-weight: 700;
  min-width: 80px;
  height: 80px;
  padding: 14px 26px;
  cursor: pointer;
  border-radius: 24px;
  line-height: 94px;
  text-align: center;
  font-size: 36px;
  line-height: 150%;
  box-shadow: 1px 4px 10px 0px rgba(151, 151, 151, 0.5);
  background: white;
  color: #3a3b3e;
}
.hg-button.hg-standardBtn:active {
  -webkit-tap-highlight-color: transparent;
  -ms-touch-action: none;
  touch-action: none;
  opacity: 0.8;
}
.hg-row {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  gap: 18px;
}
.hg-button.hg-standardBtn.bg-gray {
  background: #cbccce;
  color: #5f6165;
}
.hg-button.hg-standardBtn.bg-blue {
  background-color: #f4f8ff;
}
.hg-standardBtn[data-skbtn='Back'] {
  background: url('/src/images/Keyboard/backspace.png') no-repeat center !important;
  background-position: 18px 22px !important;
  background-size: 50% !important;
  background-color: #cbccce !important;
}
.hg-standardBtn[data-skbtn='Back'] span {
  display: none;
}
